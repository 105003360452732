import React, { Component } from 'react';
import Image from 'next/image';
import { Poppins } from 'next/font/google';
import { withRouter } from 'next/router';
import Link from 'next/link';

const popins = Poppins({ weight: '500', subsets: ['latin'] });
const extrapopins = Poppins({ weight: '700', subsets: ['latin'] });

class Navbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
          mobileMenuOpen: false,
        };
      }
    
      toggleMobileMenu = () => {
        this.setState((prevState) => ({
          mobileMenuOpen: !prevState.mobileMenuOpen,
        }));
      };

  render() {
    const { mobileMenuOpen } = this.state;
    const {router} = this.props
    const currentpage =router.pathname;
    const { backgroundColor,borderBottomColor } = this.props;
    
    return (
      <section className={`header`} style={{backgroundColor:backgroundColor , borderBottom: `1px solid ${borderBottomColor}`}}>
        <div className={`container`}>
          <div className={`df fac fjsb pd15 mpd10` }>
          <div className="df fac">
             <div className='mdn'>
              <Link href="/" className="">
                <Image
                  src={"/header/logo.webp"}
                  alt={"logo"}
                  width={"60"}
                  height={"61"}
                  loading="lazy"
                />
              </Link>
              </div>
              <div className='dn mdb'>
              <Link href="/" className="">
                <Image
                  src={"/header/logo.webp"}
                  alt={"logo"}
                  width={"50"}
                  height={"51"}
                  loading="lazy"
                />
              </Link>
              </div>
              <div className="fdc pdl10 cursor">
                <div>
                  <Link href={"/"}>
                    <p className={`fs-30 mfs-19 ylw-clr ${extrapopins.className}`}>learnsoft.org</p>
                  </Link>
                </div>
                <div className=" pdt3">
                  <p className={`cw fs-12 mfs-7 ${popins.className}`}>
                    Training | Internship | Placement
                  </p>
                </div>
              </div>
            </div>
            <div
                className={` dn mdb cursor  ${mobileMenuOpen ? "open" : ""}`}
                onClick={this.toggleMobileMenu}
              >
                <div className=' '>
                <div className="df ">
                  <div className="bar-1"></div>
                  <div className="bar-2"></div>
                </div>
                <div className="bar" style={{ marginTop: "4px" }}></div>
                <div className="df" style={{ marginTop: "4px" }}>
                  <div className="bar-3"></div>
                  <div className="bar-1"></div>
                </div></div>

              </div>

              {/* Desktop Device NavBar */}

            <div className={`df gap24 mdn`}>
              <Link href="/">
                <h6 className={`fs-19 cw ${popins.className} ${router.pathname ==='/' && !mobileMenuOpen ? "active" :""}`}>Home</h6>
              </Link>
              <Link href="/course">
                <h6 className={`fs-19 cw ${popins.className} ${router.pathname.startsWith('/course') && !mobileMenuOpen ? "active" :""} `}>Courses</h6>
              </Link>
              <Link href="/corporate-training">
                <h6 className={`fs-19 cw ${popins.className} ${router.pathname.startsWith('/corporate-training') && !mobileMenuOpen ? "active" :""} `}>Corporate </h6>
              </Link>
              <Link href="/event">
                <h6 className={`fs-19 cw ${popins.className} ${router.pathname.startsWith('/event') && !mobileMenuOpen ? "active" :""} `}>Events</h6>
              </Link>
              <Link href="/blog">
                <h6 className={`fs-19 cw ${popins.className} ${router.pathname.startsWith('/blog') && !mobileMenuOpen ? "active" :""} `}>Blogs</h6>
              </Link>
              <Link href="/about">
                <h6 className={`fs-19 cw ${popins.className} ${router.pathname ==='/about' && !mobileMenuOpen ? "active" :""} `}>About Us</h6>
              </Link>
              <Link href="/contact">
                <h6 className={`fs-19 cw ${popins.className} ${router.pathname ==='/contact' && !mobileMenuOpen ? "active" :""} `}>Contact Us</h6>
              </Link>
            </div>
            </div>
</div>
            {/* Mobile Device Nav Bar */}
    
          {mobileMenuOpen &&(

<div className={` nav-mob-open dn mdb `} style={{backgroundColor:backgroundColor , borderBottom: `1px solid ${borderBottomColor}`}}>
<div className='container'>
<div  className=''>
  <div className='df fjsb  pdt16 fac'>
<div>
<Image src={"/header/mob-logo.webp"} alt='LOGO' width={"37"} height={"48"} loading='lazy'/>
</div>

  <p className='close' onClick={this.toggleMobileMenu}>X</p>
  {/* <Image src={"/header/close-icon.webp"} alt='' width={"24"} height={"24"} loading='lazy'/> */}

  
  </div>

  <div className={` df fdc  fjsb gap8 mt48`}>
  <Link href="/">
    <h6 className={`fs-19 cw ${popins.className} ${router.pathname ==='/' && !mobileMenuOpen ? "active" :""}`}>Home</h6>
  </Link>
  <p className='line-code '></p>
<Link href={'/course'}>
<h6 className={`fs-19 cw ${popins.className} ${router.pathname ==='/course' && !mobileMenuOpen ? "active" :""}`}>Courses</h6>
</Link>
<p className='line-code '></p>
<Link href={'/corporate-training'}>
<h6 className={`fs-19 cw ${popins.className} ${router.pathname ==='/corporate-training' && !mobileMenuOpen ? "active" :""}`}>Corporate </h6>
</Link>
<p className='line-code '></p>
<Link href={'/event'}>
<h6 className={`fs-19 cw ${popins.className} ${router.pathname ==='/event' && !mobileMenuOpen ? "active" :""}`}>Events</h6>
</Link>
<p className='line-code '></p>
  <Link href="/blog">
    <h6 className={`fs-19 cw ${popins.className} ${router.pathname ==='/blog' && !mobileMenuOpen ? "active" :""} `}>Blogs</h6>
  </Link>
  <p className='line-code '></p>
  <Link href="/about">
    <h6 className={`fs-19 cw ${popins.className} ${router.pathname ==='/about' && !mobileMenuOpen ? "active" :""} `}>About Us</h6>
  </Link>
  <p className='line-code '></p>
  <Link href="/contact">
    <h6 className={`fs-19 cw ${popins.className} ${router.pathname ==='/contact' && !mobileMenuOpen ? "active" :""} `}>Contact Us</h6>
  </Link>
  <p className='line-code '></p>
</div>
</div>

{/* Media Icons */}

<div className='media-icons df fjc fac gap8'>

<div>
<Image src={"/header/insta-icon.webp"} alt='Insta-Icon' width={"31"} height={"32"} loading='lazy'/>
</div>
<div>
<Image src={"/header/fb-icon.webp"} alt='Insta-Icon' width={"31"} height={"32"} loading='lazy'/>
</div>
<div>
<Image src={"/header/x-icon.webp"} alt='Insta-Icon' width={"31"} height={"32"} loading='lazy'/>
</div>
<div>
<Image src={"/header/linkedin-icon.webp"} alt='Insta-Icon' width={"31"} height={"32"} loading='lazy'/>
</div>


</div></div>
 </div> )}


        <style jsx>
          {`
            .header {
              width: 100%;
              // background-color: #281e1e;
              // position: absolute;
              
             position: sticky;
             top: 0;
             z-index: 999;
            }
            // .no-background{
            //   width: 100%;
            //   height: 80px;
            //   position: absolute;
            //   border-bottom: 1px solid #ffffff;
            //   background-color: transparent;
            // }

            h6 {
              position: relative;
            }

            h6::before {
              content: '';
              position: absolute;
              bottom: -24px;
              left: 0;
              width: 0;
              height: 3px;
              background-color: #ffbd50;
              transition: all 0.5s;
            }

            h6:hover::before {
              width: 100%;
            }

            .active::before {
              width: 100%;
            }
            .active{
            color: #ffbd50;
            }

            h6:hover {
              color: #ffbd50;
              transition: 0.3s;
            }
            .active::before {
              width: 100%;
            }

            .bar {
                width: 39px;
                height: 4px;
                background-color: #FFA900;
                margin: 5px 0;
                transition: 0.4s;
                border-radius:3px;
              }
              .bar-1{
                width:10px;
                height:4px;
                background-color: #FFA900;
                border-radius:3px;
  
              }
              .bar-2{
                width:22px;
                height: 4px;
                background-color: #FFA900;
                border-radius:3px;
                margin-left:7px;
              }
              .bar-3{
                width:22px;
                height: 4px;
                background-color: #FFA900;
                border-radius:3px;
                margin-right:7px;
              }
       
              @media only screen and (max-width: 1023px){
                .nav-mob-open{
               position: absolute;
               width:100%;
               height:621px;
              border-radius: 0px 32px 0px 0px;
              background-color: #281E1E;
              top:0px;
              z-index: 10;
           
               }
               .header {
                width: 100%;
                // background-color: #281e1e;
                // position: absolute;
              }
              .no-background{
                width: 100%;
                height: 40.78px;
                position: absolute;
                border-bottom: 1px solid #ffffff;
                background-color: transparent;
              }
              .close{
                font-size: 26px;
                width: 20px; /* Set your desired width */
                height: 20px; /* Set your desired height */
                line-height: 20px; /* Center the text vertically */
                text-align: center; /* Center the text horizontally */
                cursor: pointer;
                color: #FAA21A;
                font-weight: 700;
              }
              .line-code {
                width: 256px;
                border: 1px solid #666666;
                background-color: #666666;
              }
              .nav-mob-open h6 {
                position: relative;
              }
              
              .nav-mob-open h6::before {
                content: '';
                position: absolute;
                bottom: -24px;
                left: 0;
                width: 0;
                height: 0; /* Set the height to 0 to remove the underline */
                background-color: transparent; /* Set the background color to transparent */
                transition: none; /* Remove transition effect */
              }
              
              // Add this to ensure the underline effect is removed on hover as well
              .nav-mob-open h6:hover::before {
                width: 0;
              }
              
              .nav-mob-open .active::before {
                width: 0;
              }
              .nav-mob-open h6:hover.active{
                color: #ffbd50;
                }
                h6.active{
                  color: #ffbd50;
                  }
                .media-icons{
                   width: 90%;
                  height: 96px;
                  background-color: #FAA21A;
                  position: absolute;
                  bottom: 0px;
                }
    
              }
          `}
        </style>
      </section>
    );
  }
}

export default withRouter (Navbar);

